<template>
  <Loading :isLoading="isLoading" />
    <div>
        <div class="w-full">
            <h2 class="text-center font-bold" style="color:#610032">
                Reporte de Prenómina
            </h2>
        </div>

        <div class="w-full">
            <label class="h2" style="color:#610032">Reporte del Periodo {{_responseDatePeriod.name}} </label>
        </div>

        <div class="w-full sm:flex mt-10">
            <div class="sm:w-1/2 text-3xl">
                <div style="color:#610032" id="label_sales_ranges_capture_period_DE"><strong>Ventas de:</strong> <label id="label_sales_ranges_capture_period_DE">{{$filters.formatDate(_responseDatePeriod.start_date)}}</label> <strong>A:</strong> <label id="label_sales_ranges_capture_period_A">{{$filters.formatDate(_responseDatePeriod.end_date)}}</label></div>
            </div>

            <div class="sm:w-1/2 text-3xl " >
                <div style="color:#610032" class="sm:float-right"><strong> Total de Comisiones: <label id="label_period_report_capture_period">{{$filters.formatCurrency(total_amount_to_pay)}}</label></strong></div>
            </div>
        </div>

        <div class="mt-5">
            <table class="width200">
                <thead>
                    <tr>
                        <th>No. Empleado</th>
                        <th>Nombre Completo</th>
                        <th>Importe a Pagar</th>
                        <th>Detalle</th>
                        <th>
                            <div class="flex"><label class="mr-16 mt-4" id="label_accept_all_capture_period">Aceptar Todas</label> <Checkbox ref="CheckboxRef" :idCheckBox="'check_accept_all_capture_period'" :cheked="acceptAllCheked" :key="acceptAllCheked" v-on:change-date-value="acceptAll($event)" /></div>
                        </th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="(data,index) of _reportsResponse.commission_list" :key="index" :style="[(index%2==0)? {'background': '#FFF'}:{'background': '#efefef' }]">
                        <td>
                            <label :id="'label_employee_name_capture_period_'+index">{{data.users_id}}</label>
                        </td>
                        <td>
                            <label :id="'label_employee_name_capture_periodo_'+index">{{data.associate_info.paternal_name}} {{data.associate_info.maternal_name}} {{data.associate_info.name}}</label>
                        </td>
                        <td>
                            <label :id="'label_total_pay_capture_periodo_'+index">{{$filters.formatCurrency(data.amount_to_pay)}}</label>
                        </td>
                        <td>
                            <button :id="'label_see_detail_capture_periodo_'+index" class=" border-2 rounded-lg px-5 py-3 w-full font-extrabold text-2xl button_see_detail_capture_periodo shadow-lg shadow-indigo-500/40" @click="getCommissionOnAssociate(data.users_id)">
                                Ver Detalle
                            </button>
                        </td>
                        <td>
                            <Checkbox ref="CheckboxRef" :idCheckBox="'check_accept_capture_period_'+index" :cheked="data.accepted" :key="data.accepted" @click="checkedElement(index,data.accepted)" />
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>

        <div class="mt-10 lg:text-right">
            <button id="button_export_report_capture_period" class="px-20 py-5 w-full lg:mx-10 lg:w-1/4" @click="exportToexcel()">Exportar Reporte</button>
            <button id="button_accept_prenomina_capture_period" class="px-20 py-5 w-full lg:w-1/4 mt-5 lg:mt-0" @click="acceptPeriod()" >Aceptar Prenómina</button>
        </div>

    </div>
</template>
<script>
import { onMounted, ref } from 'vue-demi'
import Checkbox from "@/components/Forms/Checkbox/Chek_box.vue";
import ReportsPayroll from "@/components/CommissionScheme/TableReports/ReportsPayroll.vue" 
import CommissionScheme from "@/classes/CommissionScheme.js";
import Swal from 'sweetalert2';
import Loading from '@/components/Loading/VueLoading.vue';
import { getTokenDecoden } from '@/helpers/tokenauth.js';
import { useRouter } from 'vue-router';
import Excel from "@/helpers/excel";

export default {
    name:"Payroll_report",
    components:{
        Checkbox,
        ReportsPayroll,
        Loading
    },
    setup(){
        let isLoading=ref(false);
        let acceptAllCheked=ref(false)
        let total_amount_to_pay=ref();
        let _responseDatePeriod=ref({
            accepted_by: null,
            active: true,
            closing_date: null,
            created_at: null,
            end_date: null,
            id: null,
            is_accepted: null,
            name: null,
            start_date: null,
            updated_at: null,
            users_id: null
        });
        const router = useRouter();
        let _reportsResponse=ref({
            commission_list:[],
            has_errors: false,
            message: "",
            total_amount_to_pay: 0
        });

        const CheckboxRef=ref(null);

        onMounted(async ()=>{
            await getNextPeriod();
            getCommissionSummary();
        });

        function acceptAll(value) {
            acceptAllCheked.value=value;
            for(let index=0;index<_reportsResponse.value.commission_list.length;index++){
                _reportsResponse.value.commission_list[index].accepted=value;
            }
        }

        function checkedElement(index,value){
            _reportsResponse.value.commission_list[index].accepted=!value;
            acceptAllCheked.value=false;
        }

        async function getCommissionSummary(){
            isLoading.value=true;
            let date = new Date();
            let _commisionScheme=new CommissionScheme();
            console.log("Hola",_responseDatePeriod.value)
            try{
                let _response=await _commisionScheme.get({
                    "token_auth":sessionStorage.getItem("login"),
                    "start_date":_responseDatePeriod.value.start_date,
                    "end_date": _responseDatePeriod.value.end_date
                },"foncabsa/prepayroll_report/get_resume_commission");
                if(_response.status==200){
                    _reportsResponse.value=_response.data.response;
                    total_amount_to_pay.value=_response.data.response.total_amount_to_pay;
                }
                if(_response.data.has_errors){
                    let messages="";
                    _response.data.errors.forEach(element => {
                        if(element=="Nombre del esquema ya existe"){ 
                            messages+="<strog> El nombre del esquema ya existe, ingresé otro nombre de esquema para continuar. </strong><br>"}
                        else{ messages+="<strong>■ "+element+".</strong><br>"}
                    });
                    Swal.fire({
                        title: "Aviso",
                        html:messages,
                        icon: "info",
                        confirmButtonText: "OK",
                        confirmButtonColor: '#FEB72B'
                    });
                }
                isLoading.value=false;
                setIdAttributeToAccepAndCancelButtonSwalFire('button_confirm_message','');

            }catch(error){
                console.log(error);
                isLoading.value=false;
                let messages="";
                    isLoading.value=false;
                    error.data.errors.forEach(element => {
                        messages+="<strong>■ "+element+".</strong><br>";
                    });
                Swal.fire({
                    title: error.data.response.message,
                    html:messages,
                    icon: "error",
                    confirmButtonText: "OK",
                    confirmButtonColor: '#FEB72B'
                });
                setIdAttributeToAccepAndCancelButtonSwalFire('button_confirm_message','');
            }
        }

        function getCommissionOnAssociate(users_id){
            let dataUser= getTokenDecoden();
            let date = new Date();
            try {
                router.push({ path: `/${dataUser.obj.role}/commissions/payrollreport/${users_id}`,params:{id:users_id} });
                
            } catch (error) {
                isLoading.value=false;
                let messages="";
                    isLoading.value=false;
                    error.data.errors.forEach(element => {
                        messages+="<strong>■ "+element+".</strong><br>";
                    });
                Swal.fire({
                    title: error.data.response.message,
                    html:messages,
                    icon: "error",
                    confirmButtonText: "OK",
                    confirmButtonColor: '#FEB72B'
                });
                setIdAttributeToAccepAndCancelButtonSwalFire('button_confirm_message',''); 
            }
        }

        function  setIdAttributeToAccepAndCancelButtonSwalFire(idButtonConfirm, idButtonCancel) {
            $('.swal2-confirm').attr('id', idButtonConfirm);
            $('.swal2-cancel').attr('id', idButtonCancel);
        }

        async function getNextPeriod(){
            isLoading.value=true;
            try {
                let _commisionScheme=new CommissionScheme();
                let _response=await _commisionScheme.get({ "token_auth":sessionStorage.getItem("login") },"foncabsa/sale_commission_payment_period/get_next_period");
                if(_response.status==200){
                    isLoading.value=false;
                    _responseDatePeriod.value=_response.data.response.sale_commission_payment_period;
                }
                if(_response.data.response.has_errors){
                    let messages="";
                    isLoading.value=false;
                    _response.data.errors.forEach(element => {
                        messages+="<strong>■ "+element+".</strong><br>";
                    });
                    Swal.fire({
                        title: "Aviso",
                        html:messages,
                        icon: "info",
                        confirmButtonText: "OK",
                        confirmButtonColor: '#FEB72B'
                    });
                    setIdAttributeToAccepAndCancelButtonSwalFire('button_confirm_message','');
                }
                
            } catch (error) {
                console.log(error.data)
                isLoading.value=false;
                let messages="";
                    isLoading.value=false;
                    error.data.errors.forEach(element => {
                        messages+="<strong>■ "+element+".</strong><br>";
                    });
                Swal.fire({
                    title: error.data.response.message,
                    html:messages,
                    icon: "error",
                    confirmButtonText: "OK",
                    confirmButtonColor: '#FEB72B'
                });
                setIdAttributeToAccepAndCancelButtonSwalFire('button_confirm_message','');
            }
        }

       async function acceptPeriod(){
            isLoading.value=true;
            let isValidListOfPeriod=false;
            _reportsResponse.value.commission_list.forEach(element=>{
                if(element.accepted==false){
                    isValidListOfPeriod=true;
                    return;
                }
            });

            if(!isValidListOfPeriod){
                try {
                    Swal.fire({
                        icon:"warning",
                        title:"Aviso!",
                        text:"¿Desea registrar este perido de comisión?",
                        reverseButtons:false,
                        showCancelButton: true,
                        confirmButtonText: "Continuar",
                        cancelButtonText: "Cancelar",
                        confirmButtonColor: '#FEB72B',
                        cancelButtonColor: '#FFFFFF'
                    }).then(async res=>{
                        if(res.value){
                            let _commisionScheme=new CommissionScheme();
                            let _response=await _commisionScheme.save({ "token_auth":sessionStorage.getItem("login"),
                                "token_auth":sessionStorage.getItem("login"),
                                "start_date":_responseDatePeriod.value.start_date,
                                "end_date": _responseDatePeriod.value.end_date,
                                "sale_commission_payment_period_id": _responseDatePeriod.value.id },
                                `/${process.env.VUE_APP_BUSINESS_FONCABSA}/prepayroll_report/accept_prepayroll`);
                            if(_response.status==200){
                                isLoading.value=false;
                                window.open(_response.data.response.prepayroll_report.url_document, "Descargado docuento..", "width=900, height=800");
                                await getNextPeriod();
                                getCommissionSummary();
                                acceptAllCheked.value=false;
                                Swal.fire({
                                    title: "Perido registrado.",
                                    text:"El periodo de prenómina ha sido registrado.",
                                    icon: "success",
                                    confirmButtonText: "OK",
                                    confirmButtonColor: '#FEB72B'
                                });
                                setIdAttributeToAccepAndCancelButtonSwalFire('button_ok_option_capture_period','');
                            }
                            if(_response.data.response.has_errors){
                                let messages="";
                                isLoading.value=false;
                                _response.data.errors.forEach(element => {
                                    messages+="<strong>■ "+element+".</strong><br>";
                                });
                                Swal.fire({
                                    title: "Aviso",
                                    html:messages,
                                    icon: "info",
                                    confirmButtonText: "OK",
                                    confirmButtonColor: '#FEB72B'
                                });
                                setIdAttributeToAccepAndCancelButtonSwalFire('button_confirm_message','');
                            }
                        }else{
                            isLoading.value=false
                        }
                    })
                    setIdAttributeToAccepAndCancelButtonSwalFire('button_continuar_register_period','button_cancel_register_period');        
                } catch (error) {
                    console.log(error)
                    isLoading.value=false;
                    let messages="";
                    isLoading.value=false;
                    error.data.errors.forEach(element => {
                        messages+="<strong>■ "+element+".</strong><br>";
                    });
                    Swal.fire({
                        title: error.data.response.message,
                        html:messages,
                        icon: "error",
                        confirmButtonText: "OK",
                        confirmButtonColor: '#FEB72B'
                    });
                    setIdAttributeToAccepAndCancelButtonSwalFire('button_confirm_message','');
                }
            
            }else{
                isLoading.value=false;
                Swal.fire({
                    title: "Cuidado!",
                    text:"Favor de aceptar todos los asesores para agregar a la prenomina.",
                    icon: "warning",
                    confirmButtonText: "OK",
                    confirmButtonColor: '#FEB72B'
                });
                setIdAttributeToAccepAndCancelButtonSwalFire('button_confirm_message',''); 
            }
        }

        function exportToexcel(){
            // console.log(_reportsResponse.value.commission_list);
            if(_reportsResponse.value.commission_list.length>0){
                let _exporToExcel=[];
                _reportsResponse.value.commission_list.forEach(element=>{
                        _exporToExcel.push({
                        ID:element.users_id,
                        NOMBRE_COMPLETO:`${element.associate_info.paternal_name} ${element.associate_info.maternal_name} ${element.associate_info.name} ${element.associate_info.middle_name}`,
                        IMPORTE_A_PAGAR:element.amount_to_pay,
                        ACEPTADO:element.accepted==0?"NO":"Sí"
                    });
                });
                Swal.fire({
                    icon:"success",
                    title:"Reporte generado.",
                    text:"El reporte de prenómina ha sido generado éxito.",
                    confirmButtonText: "OK",
                    confirmButtonColor: '#FEB72B'
                });
                setIdAttributeToAccepAndCancelButtonSwalFire('button_confirm_message','');
                Excel.exportExcel(_exporToExcel,_responseDatePeriod.value.start_date,_responseDatePeriod.value.end_date);

            }else{
                Swal.fire({
                    title: "Cuidado!",
                    text:"No hay elementos por exportar",
                    icon: "warning",
                    confirmButtonText: "OK",
                    confirmButtonColor: '#FEB72B'
                });
                setIdAttributeToAccepAndCancelButtonSwalFire('button_confirm_message','');
            }
        }

        return{
            _reportsResponse,
            acceptAll,
            CheckboxRef,
            checkedElement,
            isLoading,
            getCommissionOnAssociate,
            acceptAllCheked,
            total_amount_to_pay,
            _responseDatePeriod,
            acceptPeriod,
            exportToexcel
        }
    }
}
</script>

<style>

#button_cancel_register_period{
    color: #810042;
    border: 1px solid #810042;
}
 
#button_cancel_register_period:hover{
    color: white !important;
    border: 1px solid #810042 !important;
    background-color: #810042 !important;
}
 

</style>

<style scoped>
table {
  border-collapse: collapse;
  width: 100%;
}

th, td {
  text-align: center;
  padding: 10px  ;
}

tr:nth-child(even){background-color: #ffffff;}

th {
  background-color: #ffffff;
}

table.width200,table.rwd_auto {border:1px solid #810042;border-radius: 10px;width:100%;margin:0 0 50px 0}
    .width200 th,.rwd_auto th {background:#ffffff;text-align:center;padding-top: 20px;padding-bottom: 20px;}
    .width200 tr:last-child td, .rwd_auto tr:last-child td{border:50px}

.frame::-webkit-scrollbar {
  -webkit-appearance: none;
}

.width200 tbody tr { height: 70px;}

.width200 thead {border-bottom: 1px solid #810042;}


@media only screen and (max-width: 1024px)
{
    table.width200, .width200 thead, .width200 tbody, .width200 th, .width200 td, .width200 tr { display: block; }

    .width200 thead tr { position: absolute;top: -9999px;left: -9999px; }

    .width200 tr th:nth-child(5){display: none;}

    .width200 tbody tr { height: auto;}

    .width200 td { border: none;border-bottom: 1px solid #810042; position: relative;padding-left: 40% ;text-align:left }

    .width200 td:before {  position: absolute;top: 0px; left: 6px; width: 45%; padding-right: 10px; }

    .width200 thead {border-bottom: 0px solid #810042}


    td:nth-of-type(1):before { content: "No.Empleado:"; }
    td:nth-of-type(2):before { content: "Nombre:"; }
    td:nth-of-type(3):before { content: "Importe a Pagar:"; }
    td:nth-of-type(4):before { content: "Detalle:"; }
    td:nth-of-type(5):before { content: "Aceptar:"; }
    td:nth-of-type(6):before { content: "Aceptar Todo"; }

    .descarto {display:none;}
    .fontsize {font-size:10px}

    .width200{
        border-radius: 0px;
    }
}

/* Smartphones (portrait and landscape) ----------- */
@media only screen and (min-width : 320px) and (max-width : 480px)
{
    .descarto {display:none;}
}

/* iPads (portrait and landscape) ----------- */
@media only screen and (min-width: 768px) and (max-width: 1024px)
{
    .descarto {display:none;}
    .fontsize {font-size:10px}
    
}

@media only screen and (min-width: 1024px)
{
    .color-cherry-border {border:1px solid #810042}
}

@media (max-width: 300px) {
  .pagination-items {
    height: 20px;
    width: 30px;
  }
}

.button_see_detail_capture_periodo{
    transition: all 300ms;
    border: 1px solid #810042;
    color: #810042;
    background-color: white;
}
.button_see_detail_capture_periodo:hover{
    background-color: #810042;
    color: white;
}

#button_export_report_capture_period{
    transition: all 300ms;
    border: 2px solid #810042;
    color: #810042;
    background-color: white;
    border-radius: 5px;
}

#button_export_report_capture_period:hover{
    background-color: #810042;
    color: white;
}

#button_accept_prenomina_capture_period{
    border: 2px solid #FEB72B;
    background: #FEB72B;
    border-radius: 5px;
    color: white;
    font-weight: 700;
    outline: 0;
    transition: all 300ms;
}
#button_accept_prenomina_capture_period:hover{
    background: #c58400;
    border: 2px solid #c58400;
}

</style>