<template>
  <div v-if="props!=''">
    <input type="checkbox" :id="idCheckBox" v-model="valueType"><label :for="idCheckBox"></label>
  </div>
</template>

<script>
import { ref } from 'vue-demi';
export default {
  name: "Checkbox",
  props: {
    idCheckBox: {
      type: String,
      default: "",
    },
    cheked:{
      type:Boolean,
      default:false
    }
  },
  setup(props) {
    let valueType=ref(props.cheked);
    
    function changeStatus(value){
      console.log("Cambio del status:::",value)
      valueType.value=value;
    }
    return{ valueType,changeStatus }
  },
  watch:{
    valueType:{
      handler(data){
        console.log("value:::"+data);
        this.$emit('change-date-value', data);
      },
      deep:true
    }
  }
};
</script>

<style scoped>
label {
  margin-bottom: -7px;
}
input[type="checkbox"] {
  display: none;
}
input[type="checkbox"] + label {
  line-height: 3em;
}
input[type="checkbox"]:disabled + label {
  color: #ccc !important;
  cursor: not-allowed;
}
input[type="checkbox"]:checked:disabled + label:after {
  border-color: #ccc;
}
input[type="checkbox"] + label:before {
  content: "";
  width: 30px;
  height: 30px;
  float: left;
  margin-right: 0.5em;
  border: 2px solid #ccc;
  background: #fff;
  margin-top: 0.5em;
  border-radius: 7px;
}
input[type="checkbox"]:checked + label:after {
  content: "";
  width: 21px;
  height: 9px;
  border: 4px solid rgb(255, 255, 255);
  float: left;
  margin-left: -2.2em;
  border-right: 0;
  border-top: 0;
  margin-top: 1.1em;
  /* background-color: rgb(80, 228, 161);  */
  transform: rotate(-55deg);
}
input[type="checkbox"]:checked + label:before {
  border-color: rgb(80, 228, 161);
  background-color: rgb(80, 228, 161); 
}
input[type="checkbox"]:checked:disabled + label {
  background: #ccc;
  color: #fff !important;
}
input[type="checkbox"]:checked:disabled + label:before {
  border-color: #bdbdbd;
}
@media (max-width: 650px) {
  .content {
    width: 100%;
  }
}
</style>
